<template>
  <div>
    <main>
      <!-- breadcrumb-area -->
      <section class="breadcrumb-area-two breadcrumb-bg" :style="sliderStyles">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="breadcrumb-content text-center">
                <!--                      <h6 class="title" style="font-size:  x-large">{{$t('Video gallery')}}</h6>-->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- breadcrumb-area-end -->

      <!-- inner-blog-area -->
      <section class="inner-blog-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="booking-wrap">
                <ul style="margin-bottom: 30px" class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" style="width: 50%;flex: 0 0 50%;" role="presentation">
                    <button class="nav-link active" id="bOOKing-tab" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane" type="button"
                            role="tab" aria-controls="bOOKing-tab-pane" aria-selected="true"><i class="flaticon-file"></i>{{$t('News')}}</button>
                  </li>
                  <li class="nav-item" style="width: 50%;flex: 0 0 50%;" role="presentation">
                    <button class="nav-link" id="trips-tab" data-bs-toggle="tab" data-bs-target="#trips-tab-pane" type="button"
                            role="tab" aria-controls="trips-tab-pane" aria-selected="false"><i class="fa-solid fa-link"></i>{{$t('Links')}}</button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="bOOKing-tab-pane" role="tabpanel" aria-labelledby="bOOKing-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                          <div class="blog-item small-item" v-for="item in newsPost" :key="item.id">
                            <div class="blog-thumb">
                              <div>
                                <router-link :to="`/news/${item.slug}`">
                                  <img alt="" class="bvi-img fixed-image-list" v-if="!item.images.length" src="../../../assets/img/images/placeholder_size_1.jpg">
                                  <img class="bvi-img fixed-image-list" v-else :src="getMainImage(item.images)" alt="">
                                </router-link>
                              </div>
                            </div>
                            <div class="blog-content">
                              <div class="blog-meta">
                                <ul>
                                  <li class="d-flex align-items-center"><i class="ph ph-calendar-blank"></i> {{ formatDate(item.created) }}</li>
                                </ul>
                              </div>
                              <h2 class="title"><router-link :to="`/news/${item.slug}`">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</router-link></h2>
                            </div>
                          </div>
                          <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-center mt-4">
                              <li class="page-item">
                                <a class="page-link"
                                   href="#"
                                   @click="changePageNews(newsCurrentPage - 1)">
                                  {{ $t('Previous') }}
                                </a>
                              </li>
                              <li class="page-item" v-for="pageNumber in pageNumberNews" :key="pageNumber" :class="{active:newsCurrentPage == pageNumber || pageNumber === '....'}">
                                <a class="page-link"
                                   href="#" @click="changePageNews(pageNumber)">{{pageNumber}}</a>
                              </li>
                              <li class="page-item">
                                <a class="page-link"
                                   href="#"
                                   @click="changePageNews(newsCurrentPage +1)">
                                  {{ $t('NextNext') }}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="trips-tab-pane" role="tabpanel" aria-labelledby="trips-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="blog-item small-item" v-for="item in news2Post" :key="item.id">
                          <div class="blog-content">
                            <h2 class="title">
                            <router-link v-if="item.link == null" to="/#">
                              {{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}
                            </router-link>
                            <router-link v-else-if="!item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: item.link.page_slug } }">
                              {{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}
                            </router-link>
                            <router-link v-else :to="item.link.url">
                              {{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}
                            </router-link>
                            </h2>
                          </div>
                        </div>
                        <nav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageNews(news2CurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberNews2" :key="pageNumber" :class="{active:news2CurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                 href="#" @click="changePageNews(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageNews(news2CurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- inner-blog-area-end -->
    </main>
  </div>
</template>
<script>
import i18n from "@/local18";
import axios from "axios";
import getLocaleText from "@/locales/getLocaleText";
import {useBVI} from "@/useBVI";
import {computed} from "vue";

export default {
  data() {
    return {
      News:[],
      News2:[],
      itemPerPage:6,
      newsCurrentPage:1,
      news2CurrentPage:1,
    };
  },
  setup(){
    const  bviImage  = useBVI();
    const sliderStyles = computed(() => {
      if (bviImage.imageMode.value === 'off') {
        return { background: '#fff'};
      } else if (bviImage.imageMode.value === 'grayscale') {
        return { filter: 'grayscale(100%)' };
      }
      return {};
    });
    return{
      sliderStyles
    }
  },
  created() {
    this.fetchNews();
    this.fetchNews2();
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.fetchNews();
        this.fetchNews2();
      }
    },
    '$route.query.keyword': {
      immediate: true,
      handler() {
        this.fetchNews();
        this.fetchNews2();
      }
    }
  },
  computed: {
    newsPost(){
      const startPage= (this.newsCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.News.slice(startPage, endPage);
    },
    totalNews(){
      return Math.ceil(this.News.length/this.itemPerPage)
    },
    pageNumberNews(){
      let pageNumbers = []
      if (this.totalNews<=7){
        for(let i = 1;i<=this.totalNews;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.newsCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalNews];
        }
        else if (this.newsCurrentPage<=this.totalNews-3){
          pageNumbers = [1,"....",this.newsCurrentPage-2,this.newsCurrentPage-1,this.newsCurrentPage,this.newsCurrentPage+1,this.newsCurrentPage+2,"....",this.totalNews];

        }
        else {
          pageNumbers = [1,"....",this.totalNews-4,this.totalNews-3,this.totalNews-2,this.totalNews-1,this.totalNews]
        }
      }
      return pageNumbers;
    },
    news2Post(){
      const startPage= (this.news2CurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.News2.slice(startPage, endPage);
    },
    totalNews2(){
      return Math.ceil(this.News2.length/this.itemPerPage)
    },
    pageNumberNews2(){
      let pageNumbers = []
      if (this.totalNews2<=7){
        for(let i = 1;i<=this.totalNews2;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.news2CurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalNews2];
        }
        else if (this.news2CurrentPage<=this.totalNews2-3){
          pageNumbers = [1,"....",this.news2CurrentPage-2,this.news2CurrentPage-1,this.news2CurrentPage,this.news2CurrentPage+1,this.news2CurrentPage+2,"....",this.totalNews2];

        }
        else {
          pageNumbers = [1,"....",this.totalNews2-4,this.totalNews2-3,this.totalNews2-2,this.totalNews2-1,this.totalNews2]
        }
      }
      return pageNumbers;
    },
  },
  methods: {
    changePageNews(page){
      if(page >=1 && page <= this.totalNews){
        this.newsCurrentPage = page;
      }
    },
    changePageNews2(page){
      if(page >=1 && page <= this.totalVets){
        this.news2CurrentPage = page;
      }
    },
    async fetchNews() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&keyword=${this.$route.query.keyword}`);
        this.News = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchNews2() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`mainmenulinks?lang=${local}&keyword=${this.$route.query.keyword}`);
        this.News2 = response.data;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    getLocaleText,
    getMainImage(images){
      let mainImage=images.find(item =>item.main_image);
      if(mainImage){
        return mainImage.image_size_1
      }
      else{
        return images[0].image_size_1
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Bu tarih dilimine göre tarihi biçimlendirir
    }
  },
};
</script>
<style>
</style>