<template>
	<!-- breadcrumb-area -->
	<section class="breadcrumb-area-two breadcrumb-bg" :style="sliderStyles">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8">
					<div class="breadcrumb-content text-center">
						<h6 class="title" style="font-size:  x-large">{{$t('Management')}}</h6>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="blog-details-area">
		<div class="container">
      <div class="page-actions" >
        <div class="page-action-item action-share">
          <div class="d-flex align-items-center">
            <a class="text-primary facebook" @click="shareOnFacebook">
              <i class="ph ph-facebook-logo"></i>
            </a>
            <a class="text-primary twitter" @click="shareOnTwitter">
              <i class="ph ph-twitter-logo"></i>
            </a>
            <a class="text-primary whatsapp" @click="shareOnWhatsApp">
              <i class="ph ph-whatsapp-logo"></i>
            </a>
            <a class="text-primary telegram" @click="shareOnTelegram">
              <i class="ph ph-telegram-logo"></i>
            </a>
          </div>
        </div>
      </div>
			<div class="inner-blog-content blog-details-content">
				<div class="row" id="team">
					<div class="col-12" v-for="post in leaders" :key="post.id">
						<div class="team-leader team-member">
							<router-link class="stretched-link" :to="`/leadership/${post.slug}`"></router-link>
							<div class="leader-img">
								<img :src="post.image_size_3" class="bvi-img img-responsive" alt="">
							</div>
							<div class="leader-content">
								<div class="leader-name">
									<h2 style="color: #0d6efd">{{getLocaleText(post.name_kg, post.name_ru, post.name_en)}} <i class="fa fa-angle-right" style="margin-left: 5px"></i></h2>
								</div>
								<div class="leader-position">
									<span>{{getLocaleText(post.job_title_kg, post.job_title_ru, post.job_title_en)}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-3" v-for="post in managers" :key="post.id">
						<div class="team-member">
							<router-link class="stretched-link" :to="`/leadership/${post.slug}`"></router-link>
							<div class="overlay-wrapper2">
								<img :src="post.image" class="bvi-img img-responsive" alt="">
								<span class="overlay" style="display: none;">
									<a href=""><i class="fa fa-facebook"></i></a>
									<a href=""><i class="fa fa-twitter"></i></a>
									<a href=""><i class="fa fa-linkedin"></i></a>
								</span>
							</div>
							<span><strong>{{getLocaleText(post.name_kg, post.name_ru, post.name_en)}}</strong></span>
							<span class="pt-0">{{getLocaleText(post.job_title_kg, post.job_title_ru, post.job_title_en)}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- breadcrumb-area-end -->

</template>

<script setup>
import {computed, reactive} from "vue";
import axios from 'axios';
import getLocaleText from "@/locales/getLocaleText";
import {useBVI} from "@/useBVI";
const leaders = reactive([]);
const managers = reactive([]);
const currentUrl = window.location.href;
const  bviImage  = useBVI();
const sliderStyles = computed(() => {
  if (bviImage.imageMode.value === 'off') {
    return { background: '#fff'};
  } else if (bviImage.imageMode.value === 'grayscale') {
    return { filter: 'grayscale(100%)' };
  }
  return {};
});
const shareOnTwitter = () => {
  window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`, '_blank', 'width=400,height=300');
}

const shareOnFacebook = () => {
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`, '_blank', 'width=400,height=300');
}

const shareOnWhatsApp = () => {
  window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`, '_blank');
}

const shareOnTelegram = () => {
  window.open(`tg://msg_url?url=${encodeURIComponent(currentUrl)}`, '_blank');
}
(async function () {
	const response = await axios.get('managements');

	response.data.forEach(obj => {
		if (obj.job_type == 1) {
			leaders.push(obj);
		} else if (obj.job_type == 2) {
			managers.push(obj);
		}
	});
})();
</script>

<style>
.stretched-link {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	cursor: pointer;
}

.overlay-wrapper2 {
	height: 320px;
	overflow: hidden;
	margin: 0 auto;
}

.overlay-wrapper2 img {
	display: block;
	margin: auto;
	object-fit: cover;
}

.team-leader {
	display: flex;
	flex-direction: column;
	/*overflow: hidden;*/
	height: auto !important;
	margin-bottom: 30px;
}

@media(min-width: 768px) {
	.team-leader {
		display: flex;
		flex-direction: row;
	}
}

.team-leader.team-member {
	/*overflow: hidden;*/
	border: none;
	box-shadow: none !important;
	position: relative;
}

.leader-img{
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}
.leader-img img {
	object-fit: cover;
  height: 400px;
}

.team-leader .leader-content {
	padding: 20px 35px;
}

#team img {
	border: 1px solid #EBEBEB;
	background-color: #F5F5F5;
}

#team h4 {
	color: #555555;
	font-size: 20px;
	font-weight: 300;
	margin: 0 0 5px;
	font-family: 'Open Sans', Verdana, Geneva, sans-serif;
	background-color: #EBEBEB;
	padding: 15px;
}

#team span {
  padding: 15px;
	font-size: 14px;
	display: block;
}

#team .leader-position span{
  padding: 0;
}

#team p {
	color: #777777;
	font-size: 13px;
	min-height: 70px;
	padding: 0 10px 10px;
}

#team .team-member {
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
	height: 450px;
	position: relative;
}

#team .team-member .overlay-wrapper2 {
	position: relative;
	background-color: #F5F5F5;
}

#team .team-member .overlay-wrapper2 .overlay {
	background-color: rgba(0, 0, 0, 0.7);
	top: 10px;
	left: 0;
	display: none;
	position: absolute;
	right: 0;
	height: 15%;
	padding: 0;
	margin: 0 11px;
	text-align: center;
}

#team .team-member .overlay-wrapper2 .overlay i {
	font-size: 18px;
	color: rgba(255, 255, 255, 0.8);
	display: inline-block;
	padding: 10px 15px 0;
}

#team .team-member .overlay-wrapper2 .overlay i:hover {
	color: rgba(255, 255, 255, 1);
}
</style>