import i18n from "@/local18.js"

export default function getLocaleText(textKg, textRu, textEn) {
	const lang = i18n.global.locale.value;
	let title = "";

	// если заголовок за выбранный язык пустой то взять заголовок другого языка
	if (lang === "kg") title = textKg;
	if (lang === "ru") title = textRu;
	if (lang === "en") title = textEn;

	if (typeof title !== 'string') {
		return "";
	}

	return title;
}