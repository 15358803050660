<template>
	<v_slider />
	<!-- <v_booking /> -->
	<SearchForm/>
	<V_feature />
	<v_news />
	<v_links />
	<v_mobile />
  <v_siteslink/>
</template>

<script>
import 'slick-carousel';
import V_news from '../components/home/News.vue'
import V_mobile from '../components/home/Mobile.vue'
import V_links from '../components/home/Links.vue'
import V_slider from '../components/home/Slider.vue'
// import V_booking from '../components/home/V_Booking.vue'
import SearchForm from '@/components/SearchForm/SearchForm.vue';
import V_feature from '../components/home/Feature.vue'
import V_siteslink from "@/components/home/SitesLink.vue";

export default {
	name: 'V_home',
	setup() {

	},
	components: {
    'v_siteslink':V_siteslink,
		'v_news': V_news,
		'v_mobile': V_mobile,
		'v_links': V_links,
		'v_slider': V_slider,
		// 'v_booking': V_booking,
		'V_feature': V_feature,
		'SearchForm': SearchForm
	},
	methods: {

	},
}
</script>