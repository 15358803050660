<template>
	<!-- breadcrumb-area -->
	<section class="breadcrumb-area-two breadcrumb-bg" :style="sliderStyles">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8">
					<div class="breadcrumb-content text-center">
            <h6 class="title" style="font-size:  x-large">{{$t('Management')}}</h6>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="section section-team section-member my-5">
		<div class="container">
      <div class="page-actions" style="margin-bottom: 7px">
        <div class="page-action-item action-share">
          <div class="d-flex align-items-center">
            <a class="text-primary facebook" @click="shareOnFacebook">
              <i class="ph ph-facebook-logo"></i>
            </a>
            <a class="text-primary twitter" @click="shareOnTwitter">
              <i class="ph ph-twitter-logo"></i>
            </a>
            <a class="text-primary whatsapp" @click="shareOnWhatsApp">
              <i class="ph ph-whatsapp-logo"></i>
            </a>
            <a class="text-primary telegram" @click="shareOnTelegram">
              <i class="ph ph-telegram-logo"></i>
            </a>
          </div>
        </div>
      </div>
			<div class="row">
        <div class="col-lg-12">
          <div class="booking-wrap">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="bOOKing-tab" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane" type="button"
                        role="tab" aria-controls="bOOKing-tab-pane" aria-selected="true"><i class="fas fa-user"></i>{{$t('Biography')}}</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="bOOKing-tab2" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane2" type="button"
                        role="tab" aria-controls="bOOKing-tab-pane2" aria-selected="true"><i class="fas fa-calendar-check"></i>{{getLocaleText(component_of_element.title_kg, component_of_element.title_ru, component_of_element.title_en)}}</button>
              </li>
              <li class="nav-item" role="presentation" v-if="element.job_type==1">
                <button class="nav-link" id="trips-tab" data-bs-toggle="tab" data-bs-target="#trips-tab-pane" type="button"
                        role="tab" aria-controls="trips-tab-pane" aria-selected="false"><i class="flaticon-file"></i> {{$t('Activity')}}</button>
              </li>
              <li class="nav-item" role="presentation" v-if="element.job_type==1">
                <button class="nav-link" id="check-tab" data-bs-toggle="tab" data-bs-target="#check-tab-pane" type="button"
                        role="tab" aria-controls="check-tab-pane" aria-selected="false"><i class="flaticon-tick"></i> {{$t('Appeals')}}</button>
              </li>
              <li class="nav-item" role="presentation" v-if="element.job_type==1">
                <button class="nav-link" id="flight-tab" data-bs-toggle="tab" data-bs-target="#flight-tab-pane" type="button"
                        role="tab" aria-controls="flight-tab-pane" aria-selected="false"><i class="fas fa-film"></i> {{$t('Video gallery')}}</button>
              </li>
            </ul>
            <div class="row" style="padding-top: 15px;">
              <div class="col-md-8">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="bOOKing-tab-pane" role="tabpanel" aria-labelledby="bOOKing-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="tab-content-wrap important_style" v-html="getLocaleText(element.content_kg,element.content_ru,element.content_en)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="bOOKing-tab-pane2" role="tabpanel" aria-labelledby="bOOKing-tab2" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="tab-content-wrap important_style" v-html="getLocaleText(component_of_element.content_kg,component_of_element.content_ru,component_of_element.content_en)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="trips-tab-pane" role="tabpanel" aria-labelledby="trips-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in newsPost" :key="item.id">
                              <div class="fly-next-item">
                                <div class="fly-next-thumb">
                                  <router-link :to="{ path: '/news_detail', query: { slug: item.slug,categoryDate: 'activities'} }">
                                    <img alt="" class="bvi-img " v-if="!item.images.length" src="@/assets/img/images/placeholder_size_2.jpg">
                                    <img class="bvi-img " v-else :src="getMainImage(item.images)" alt="">
                                  </router-link>
                                </div>
                                <div class="fly-next-content">
                                  <span>{{ formatDate(item.created) }}</span>
                                  <h4 class="title fly-next-content-title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        <nav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageNews(newsCurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberNews" :key="pageNumber" :class="{active:newsCurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                 href="#" @click="changePageNews(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageNews(newsCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="check-tab-pane" role="tabpanel" aria-labelledby="check-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in news2Post" :key="item.id">
                            <div class="fly-next-item">
                              <div class="fly-next-thumb">
                                <router-link :to="{ path: '/news_detail', query: { slug: item.slug,categoryDate: 'director_appeals'} }">
                                  <img alt="" class="bvi-img " v-if="!item.images.length" src="@/assets/img/images/placeholder_size_2.jpg">
                                  <img class="bvi-img " v-else :src="getMainImage(item.images)" alt="">
                                </router-link>
                              </div>
                              <div class="fly-next-content">
                                <span>{{ formatDate(item.created) }}</span>
                                <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <nav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageNews2(news2CurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberNews2" :key="pageNumber" :class="{active:news2CurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                 href="#" @click="changePageNews2(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageNews2(new2sCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="flight-tab-pane" role="tabpanel" aria-labelledby="flight-tab" tabindex="0">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row fly-next-active justify-content-center">
                          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer" v-for="item in videosPost" :key="item.id">
                            <div class="fly-next-item">
                              <div class="fly-next-thumb">
                                <router-link :to="{ path: '/videogalery_detail', query: { slug: item.slug,categoryDate: 'activities'} }">
                                  <img alt="" class="bvi-img " v-if="item.image==null" src="../../../assets/img/images/placeholder_size_2.jpg">
                                  <img class="bvi-img " v-else :src="item.image_size_2" alt="">
                                </router-link>
                              </div>
                              <div class="fly-next-content">
                                <span>{{ formatDate(item.created) }}</span>
                                <h4 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <nav aria-label="Page navigation">
                          <ul class="pagination justify-content-center mt-4">
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageVideo(videoCurrentPage - 1)">
                                {{ $t('Previous') }}
                              </a>
                            </li>
                            <li class="page-item" v-for="pageNumber in pageNumberVideo" :key="pageNumber" :class="{active:videoCurrentPage == pageNumber || pageNumber === '....'}">
                              <a class="page-link"
                                 href="#" @click="changePageVideo(pageNumber)">{{pageNumber}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link"
                                 href="#"
                                 @click="changePageVideo(videoCurrentPage +1)">
                                {{ $t('NextNext') }}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card no-hover">
                  <div class="card-img">
                    <img class="bvi-img card-img-top" :src='element.image'>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      {{ getLocaleText(element.name_kg, element.name_ru, element.name_en,) }}
                    </h5>
                    <div class="card-subtitle d-flex">
                      <a href="#">{{getLocaleText(element.job_title_kg, element.job_title_ru, element.job_title_en, )}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import getLocaleText from "@/locales/getLocaleText";
import i18n from "@/local18";
import {useBVI} from "@/useBVI";
import {computed} from "vue";
export default {
  name: 'liderView',
  setup(){
    const  bviImage  = useBVI();
    const sliderStyles = computed(() => {
      if (bviImage.imageMode.value === 'off') {
        return { background: '#fff'};
      } else if (bviImage.imageMode.value === 'grayscale') {
        return { filter: 'grayscale(100%)' };
      }
      return {};
    });
    return{
      sliderStyles
    }
  },
  data() {
    return {
      currentUrl:window.location.href,
      element: {},
      News:[],
      News2:[],
      VideoGalery:[],
      itemPerPage:8,
      videoCurrentPage:1,
      newsCurrentPage:1,
      news2CurrentPage:1,
      component_of_element:{},
    };
  },
  computed:{
    newsPost(){
      const startPage= (this.newsCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.News.slice(startPage, endPage);
    },
    totalNews(){
      return Math.ceil(this.News.length/this.itemPerPage)
    },
    pageNumberNews(){
      let pageNumbers = []
      if (this.totalNews<=7){
        for(let i = 1;i<=this.totalNews;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.newsCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalNews];
        }
        else if (this.newsCurrentPage<=this.totalNews-3){
          pageNumbers = [1,"....",this.newsCurrentPage-2,this.newsCurrentPage-1,this.newsCurrentPage,this.newsCurrentPage+1,this.newsCurrentPage+2,"....",this.totalNews];

        }
        else {
          pageNumbers = [1,"....",this.totalNews-4,this.totalNews-3,this.totalNews-2,this.totalNews-1,this.totalNews]
        }
      }
      return pageNumbers;
    },
    news2Post(){
      const startPage= (this.news2CurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.News2.slice(startPage, endPage);
    },
    totalNews2(){
      return Math.ceil(this.News2.length/this.itemPerPage)
    },
    pageNumberNews2(){
      let pageNumbers = []
      if (this.totalNews2<=7){
        for(let i = 1;i<=this.totalNews2;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.news2CurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalNews2];
        }
        else if (this.news2CurrentPage<=this.totalNews2-3){
          pageNumbers = [1,"....",this.news2CurrentPage-2,this.news2CurrentPage-1,this.news2CurrentPage,this.news2CurrentPage+1,this.news2CurrentPage+2,"....",this.totalNews2];

        }
        else {
          pageNumbers = [1,"....",this.totalNews2-4,this.totalNews2-3,this.totalNews2-2,this.totalNews2-1,this.totalNews2]
        }
      }
      return pageNumbers;
    },
    videosPost(){
      const startPage= (this.videoCurrentPage - 1) * this.itemPerPage;
      const endPage= startPage+this.itemPerPage;
      return this.VideoGalery.slice(startPage, endPage);
    },
    totalVideos(){
      return Math.ceil(this.VideoGalery.length/this.itemPerPage)
    },
    pageNumberVideo(){
      let pageNumbers = []
      if (this.totalVideos<=7){
        for(let i = 1;i<=this.totalVideos;i++){
          pageNumbers.push(i);
        }
      }
      else {
        if(this.videoCurrentPage<=4){
          pageNumbers = [1,2,3,4,5,"....",this.totalPages];
        }
        else if (this.videoCurrentPage<=this.totalVideos-3){
          pageNumbers = [1,"....",this.videoCurrentPage-2,this.videoCurrentPage-1,this.videoCurrentPage,this.videoCurrentPage+1,this.videoCurrentPage+2,"....",this.totalVideos];

        }
        else {
          pageNumbers = [1,"....",this.totalVideos-4,this.totalVideos-3,this.totalVideos-2,this.totalVideos-1,this.totalVideos]
        }
      }
      return pageNumbers;
    }
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.fetchData();
        this.fetchNews();
        this.fetchNews2();
        this.fetchVideoGalery();
      }
    }
  },
  created() {
    this.fetchData();
    this.fetchNews();
    this.fetchNews2();
    this.fetchVideoGalery();
  },
  methods: {
    shareOnTwitter (){
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnFacebook(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnWhatsApp(){
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    shareOnTelegram(){
      window.open(`tg://msg_url?url=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    changePageNews(page){
      if(page >=1 && page <= this.totalNews){
        this.newsCurrentPage = page;
      }
    },
    changePageNews2(page){
      if(page >=1 && page <= this.totalVets){
        this.news2CurrentPage = page;
      }
    },
    changePageVideo(page){
      if(page >=1 && page <= this.totalVideos){
        this.videoCurrentPage = page;
      }
    },
    async fetchData() {
      try {
        if (!this.$route.params.slug) {
          console.error('Link prop is not set correctly.');
          return;
        }
        const response = await axios.get(`managements/${this.$route.params.slug}`);
        this.element = response.data;
        this.component_of_element= this.element.components.find(item=>item.id);
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchNews() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&category=activities`);
        this.News = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchNews2() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`news?lang=${local}&category=director_appeals`);
        this.News2 = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    async fetchVideoGalery() {
      try {
        const local = i18n.global.locale.value;
        const response = await axios.get(`video_galery?page_size=10&lang=${local}&category=activities`);
        this.VideoGalery = response.data.results;
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    getLocaleText,
    getMainImage(images){
      let mainImage=images.find(item =>item.main_image);
      if(mainImage){
        return mainImage.image_size_2
      }
      else{
        return images[0].image_size_2
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    }
  },
}
</script>


<style>
.card {
  gap: 0;
  padding: 0;
  border: 0;
}

.card-body{
  padding: 24px;
}

.card-title{
  margin-bottom: 24px;
}


.btn {
	border-radius: 0;
	background: #4d5aa3;
	color: #fff;
	padding-left: 30px;
	padding-right: 30px;
	margin-right: 7px;
}

.btn:hover{
	border-radius: 0;
	background: #222c62;
}

.section-tools-wrapper>div {
	/* margin-right: 20px; */
}

.section-member .section-body article,
.section-member .section-body article p,
.section-member .section-body article span{
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
}
</style>