<template>
  <div class="relative flex items-center z-20 cursor-pointer">
    <!-- eslint-disable -->
    <i class="ph ph-calendar-blank absolute left-4 text-[25px] z-20"></i>
    <input
      :value="dateString"
      id="departure"
      type="text"
      :placeholder="placeholder"
      disabled
      class="p-[15px] !border-none outline-none bg-gray-100 pl-[50px] rounded-xl w-full cursor-pointer relative z-10 pointer-events-none" />
  </div>
</template>

<script setup>
import moment from 'moment'
import { computed, onMounted, defineProps } from 'vue'

const props = defineProps(['date', 'placeholder'])

const dateString = computed(() => {
  return props.date ? moment(props.date).format('DD.MM.YYYY') : ''
})

onMounted(() => {})
</script>

<style lang="scss" scoped></style>
