<template>
  <div class="col-lg-12">
    <div class="inner-blog-item">
      <div class="inner-blog-thumb" v-if="true">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="slider">
              <div class="slide" v-for="(item, index) in images" :key="index">
                <img class="bvi-img " :src="item" alt="Slider Image">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inner-blog-content">
        <h2 class="title">{{ getLocaleText(newsData.title_kg,newsData.title_ru,newsData.title_en)}}</h2>
        <div class="important_style" v-html="getLocaleText(newsData.content_kg,newsData.content_ru,newsData.content_en)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";

export default {
  data() {
    return {
      readyToMount: false,
      newsData: {},
      images:[],
    };
  },
  props: ['slug'],
  setup() {
    return {getLocaleText};
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        if (!this.slug) {
          console.error('Link prop is not set correctly.');
          return;
        }
        const response = await axios.get(`image_galery/${this.slug}`);
        this.newsData = response.data;
        this.newsData.images.forEach((item)=>{
          this.images.push(item.image_size_3)
        });
        this.readyToMount = true;

      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    initSlider() {
      this.$nextTick(() => {
        window.$('.slider').slick({
          autoplay: true,
          autoplaySpeed: 3000,
          dots: true,
          arrows: true, // Arrows are enabled here
          prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button class="slick-next" aria-label="Next" type="button"><i class="fas fa-chevron-right"></i></button>'
        });
      });
    }
  },
  watch: {
    readyToMount(newValue) {
      if (newValue) {
        this.initSlider();
      }
    },
  }
}
</script>
<style>
/* CSS styles for slider */
.slider {
  position: relative;
}

.slide {
  position: relative;
}

.slide img {
  width: 100%;
  height: auto;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.slick-dots li button {
  font-size: 10px;
  line-height: 10px;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #fff;
  color: transparent;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background-color: #ff0000; /* Aktif nokta rengi */
}

.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:hover, .slick-next:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
</style>