import { createI18n } from "vue-i18n";
import ru from './locales/ru.json';
import kg from './locales/kg.json';
import en from '@/locales/en.json';

function loadLocaleMessages() {
	const locales = [{ ru }, { kg }, { en }];
	const messages = {}
	locales.forEach(lang => {
		const key = Object.keys(lang)
		messages[key] = lang[key]
	})
	return messages
}

export default createI18n({
	locale: localStorage.getItem("lang") ?? "kg",
	fallbackLocale: 'ru',
	legacy: false,
	fallbackWarn: false, // Установите параметр на true, чтобы отключить предупреждения
	missingWarn: false,
	messages: loadLocaleMessages()
})