<template>
    <!-- destination-area -->
    <section class="destination-area destination-bg" style="padding: 100px 0 50px 0;" :style="sliderStyles">
        <div class="container" >
            <div class="row">
                <div class="col-lg-8">
                    <div class="section-title">
                        <h2 class="title">{{ $t('Mobile application') }}</h2>
                    </div>
                    <div class="flex gap-5 flex-col lg:flex-row max-w-[100%] lg:max-w-[50%]">
            <div class="flex flex-col items-center">
              <a
                href="https://play.google.com/store/apps/details?id=kg.ticket.ticket"
                target="_blank"
                class="flex-1 flex gap-5 bg-white text-[#333333] text-2xl items-center rounded-md py-[15px] px-[30px] justify-center hover:no-underline">
                Google Play
                <img
                  alt=""
                  src="@/assets/img/images/google-play.svg"
                  class="bvi-img  h-[50px]" />
              </a>
              <img
                src="@/assets/img/images/google_play.jpeg"
                class="bvi-img w-[150px] mt-5"
                alt="" />
            </div>
            <div class="flex flex-col items-center">
              <a
                href="https://apps.apple.com/kg/app/railway-kg/id6502640387"
                target="_blank"
                class="flex-1 flex gap-5 bg-white text-[#333333] text-2xl items-center rounded-md py-[15px] px-[30px] justify-center hover:no-underline">
                App Store
                <img
                  alt=""
                  src="@/assets/img/images/apple.svg"
                  class="bvi-img h-[50px]" />
              </a>
              <img
                src="@/assets/img/images/app_store.jpeg"
                class="bvi-img w-[150px] mt-5"
                alt="" />
            </div>
          </div>
                    <!-- <div class="destination-content">
                      <ul>
                            <li>
                                <div class="counter-item">
                                    <div class="counter-content">
                                        <h2 class="count">Google Play</h2>
                                    </div>
                                    <div class="counter-icon">
                                        <img src="@/assets/img/images/google-play.svg">
                                    </div>
                                </div>
                                <a href="https://play.google.com/store/apps/details?id=kg.ticket.ticket" class="stretched-link"></a>
                            </li>
                            <li>
                                <div class="counter-item">
                                    <div class="counter-content">
                                        <h2 class="count">App Store</h2>
                                    </div>
                                    <div class="counter-icon">
                                        <img src="@/assets/img/images/apple.svg">
                                    </div>
                                </div>
                                <a href="https://apps.apple.com/kg/app/railway-kg/id6502640387" class="stretched-link"></a>
                            </li>
                        </ul>
                      <ul>
                        <li>
                          <div class="counter-item" style="background: none;padding: 20px 50px">
                            <img src="@/assets/img/images/google_play.jpeg">
                          </div>
                        </li>
                        <li>
                          <div class="counter-item" style="background: none; padding: 20px 50px">
                            <img src="@/assets/img/images/app_store.jpeg">
                          </div>
                        </li>
                      </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
    <!-- destination-area-end -->
</template>
<script>
import {useBVI} from "@/useBVI";
import {computed} from "vue";

export default{
    name:"V_mobile",
    data(){
        return{

        }
    },
    setup(){
      const  bviImage  = useBVI();
      const sliderStyles = computed(() => {
        if (bviImage.imageMode.value === 'off') {
          return { background: '#fff'};
        } else if (bviImage.imageMode.value === 'grayscale') {
          return { filter: 'grayscale(100%)' };
        }
        return {};
      });
      return{
        bviImage,
        sliderStyles,
      }
    }
}
</script>
<style>
</style>