<template>

	<!-- blog-area -->
	<section class="blog-area blog-bg">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8">
					<div class="section-title text-center">
						<h2 class="title"><router-link to="/news">{{ $t('Last news') }}</router-link></h2>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-md-7">
					<div class="blog-item" v-if="getNews()[0]">
						<div class="blog-thumb">
              <router-link :to="`/news/${getNews()[0].slug}`" >
                <img class="bvi-img" alt="" v-if="!getNews()[0].images.length" src="../../assets/img/images/placeholder_size_3.jpg">
                <img class="bvi-img" v-else :src="getMainImage(getNews()[0].images)" alt="">
              </router-link>
						</div>
						<div class="blog-content">
							<div class="blog-meta">
								<ul>
									<li class="d-flex align-items-center"><i class="ph ph-calendar-blank"></i>{{ formatDate(getNews()[0].created) }}</li>
								</ul>
							</div>
							<h2 class="title"><router-link :to="`/news/${getNews()[0].slug}`">{{ getLocaleText(getNews()[0].title_kg, getNews()[0].title_ru, getNews()[0].title_en) }}</router-link></h2>
						</div>
					</div>
				</div>
				<div class="col-md-5">
					<div class="blog-item small-item" v-for="item in getNews().slice(1,4)" :key="item.id">
						<div class="blog-thumb">
							<div>
								<router-link :to="`/news/${item.slug}`">
                  <img class="bvi-img" alt="" v-if="!item.images.length" src="../../assets/img/images/placeholder_size_1.jpg">
                  <img class="bvi-img" v-else :src="getMainImage(item.images)" alt="">
                </router-link>
							</div>
						</div>
						<div class="blog-content">
							<div class="blog-meta">
								<ul>
									<li class="d-flex align-items-center"><i class="ph ph-calendar-blank"></i> {{ formatDate(item.created) }}</li>
								</ul>
							</div>
							<h2 class="title"><router-link :to="`/news/${item.slug}`">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}</router-link></h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- blog-area-end -->
</template>
<script>
import axios from 'axios';
import getLocaleText from "@/locales/getLocaleText";
import i18n from "@/local18";

export default {
	name: 'NewsItem',
	data() {
		return {
      kgNews:[],
      ruNews:[],
      enNews:[],
		};
	},
	setup() {
		return { getLocaleText };
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		async fetchData() {
			try {
        const response2 = await axios.get('news?page_size=4&lang=kg&category=allnews');
        this.kgNews = response2.data.results;
        const response3 = await axios.get('news?page_size=4&lang=ru&category=allnews');
        this.ruNews = response3.data.results;
        const response4 = await axios.get('news?page_size=4&lang=en&category=allnews');
        this.enNews = response4.data.results;
			} catch (error) {
				console.error('API data fetch error:', error);
			}
		},
    getNews: function () {
      const lang = i18n.global.locale.value;
      let list = [];

      // если заголовок за выбранный язык пустой то взять заголовок другого языка
      if (lang === "kg") list = this.kgNews;
      if (lang === "ru") list = this.ruNews;
      if (lang === "en") list = this.enNews;
      return list;
    },
    getMainImage(images){
      let mainImage=images.find(item =>item.main_image);
      if(mainImage){
        return mainImage.image_size_3
      }
      else{
        return images[0].image_size_3
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Bu tarih dilimine göre tarihi biçimlendirir
    }
  },
};
</script>

<style>
.ribbon {
  width: auto;
  margin: 0 auto 0px;
  position: relative;
  color: #fff;
  font: 28px/60px sans-serif;
  text-align: center;
  text-transform: uppercase;
  background: #0d6efd;
  top: -140px;
}

.ribbon i {
  position: absolute;
}

.ribbon i:first-child, .ribbon i:nth-child(2) {
  position: absolute;
  left: -20px;
  bottom: -20px;
  z-index: -1;
  border: 20px solid transparent;
  border-right-color: #043140;
}

.ribbon i:nth-child(2) {
  left: auto;
  right: -20px;
  border-right-color: transparent;
  border-left-color: #043140;
}

.ribbon i:nth-child(3), .ribbon i:last-child {
  width: 20px;
  bottom: -20px;
  left: -60px;
  z-index: 3;
  border: 30px solid #1D53C0;
  border-left-color: transparent;
}

.ribbon i:last-child {
  bottom: -20px;
  left: auto;
  right: -60px;
  border: 30px solid #1D53C0;
  border-right-color: transparent;
}
</style>