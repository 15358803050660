<template>
  <!-- booking-area -->
  <div class="booking-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="booking-wrap">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button style="border-radius: 20px 20px 0 0; background-color: rgb(205 224 229 / 75%);" class="nav-link active" id="bOOKing-tab" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane" type="button" role="tab" aria-controls="bOOKing-tab-pane" aria-selected="true">
                  <i class="ph ph-train"></i>
                  {{ $t('Ticket booking') }}
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="bOOKing-tab-pane" role="tabpanel" aria-labelledby="bOOKing-tab" tabindex="0">
                <div class="tab-content-wrap book-border" style="background-color: rgb(205 224 229 / 75%);">
                  <div class="bg-white py-[25px] px-[30px] rounded-xl w-full ">
                    <form style=""
                      class="flex relative gap-2 flex-col lg:flex-row"
                      @submit.prevent>
                      <CitySelector
                        :default-city-id="defaultDepartureCity?.id"
                        :default-city-name="defaultDepartureCity?.name"
                        :cities="stations"
                        :fromCity="fromCity"
                        :toCity="toCity"
                        :toCityName="toCityName"
                        :fromCityName="fromCityName"
                        :placeholder="'Откуда'"
                        directionType="from"
                        class="flex-1 w-full"
                        @setFromCityName="(v) => fromCityName = v"
                        @setToCityName="(v) => toCityName = v"
                        @setToCity="(v) => toCity = v"
                        @setFromCity="(v) => fromCity = v"
                      />
                      <BaseButton
                        class="w-[53px] h-[53px] !rounded-xl absolute lg:relative max-lg:right-4 max-lg:top-7 z-10 flex justify-center"
                        @click="swap">
                        <i class="ph ph-repeat text-2xl"></i>
                      </BaseButton>
                      <CitySelector
                        :default-city-id="defaultArrivalCity?.id"
                        :default-city-name="defaultArrivalCity?.name"
                        :placeholder="'Куда'"
                        :cities="stations"
                        :fromCity="fromCity"
                        :toCity="toCity"
                        directionType="to"
                        :toCityName="toCityName"
                        :fromCityName="fromCityName"
                        class="flex-1"
                        @setFromCityName="(v) => fromCityName = v"
                        @setToCityName="(v) => toCityName = v"
                        @setToCity="(v) => toCity = v"
                        @setFromCity="(v) => fromCity = v" />

                      <div class="flex-1">
                        <DateSelector
                          :date="dateRange.start"
                          placeholder="Когда"
                          @click="showFromCalendar" />
                        <div
                          v-if="showDatePicker"
                          ref="target"
                          class="shadow-md p-3 rounded-md absolute bg-white top-[60px] z-30">
                          <div class="flex justify-between items-center mb-3 text-sm font-bold px-2 flex-col xl:flex-row gap-3">
                            Выберите дату
                            <button
                              class="bg-gray-200 p-2 px-3 rounded-md text-xs text-black border-none"
                              @click="setNoReturnTicket">
                              Обратный билет не нужен
                            </button>
                          </div>
                          <DatePicker
                            ref="calendar"
                            v-model="vModel"
                            :attributes="attributes"
                            :is-range="isRange"
                            :min-date="new Date()"
                            :columns="columns"
                            :rows="rows"
                            borderless
                            @update:modelValue="val => dateSelection(val)"
                            @drag="onDrag" />
                        </div>
                      </div>
                      <DateSelector
                        :date="dateRange.end"
                        placeholder="Обратно"
                        class="flex-1"
                        @click="showToCalendar" />
                      <PersonSelector :person="person" class="w-[140px]" @setPerson="(v) => person = v" />
                      <BaseButton
                        :disabled="isLoading"
                        class="lg:w-[53px] h-[53px] !rounded-xl w-full justify-center"
                        @click="handleSubmit">
                        <i
                          v-if="isLoading"
                          class="ph ph-spinner-gap text-2xl"></i>
                        <i
                          v-else
                          class="ph ph-magnifying-glass text-2xl"></i>
                      </BaseButton>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- booking-area-end -->
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import moment from 'moment'
import CitySelector from '@/components/SearchForm/CitySelector.vue'

import '@/assets/css/home/booking.css';
import i18n from "@/local18";
import { onClickOutside } from '@vueuse/core'
import DateSelector from '@/components/SearchForm/DateSelector.vue'
import { DatePicker } from 'v-calendar'
import BaseButton from '@/components/BaseButton.vue'
import 'v-calendar/style.css'
import { useScreens } from 'vue-screen-utils'
import PersonSelector from './PersonSelector.vue'
import axios from "axios";

const { mapCurrent } = useScreens({ xs: '0px', sm: '640px', md: '768px', lg: '1024px', xl: '1240px' })
const columns = mapCurrent({ xl: 2 }, 1)
const rows = mapCurrent({ xl: 1 }, 2)

const route = useRoute()

const fromCity = ref()
const toCity = ref()

const fromCityName = ref()
const toCityName = ref()


const isLoading = ref(false)
const calendar = ref()
const secondDate = ref()
const showDatePicker = ref(false)
const dateSelectionCounter = ref(0)
const person = ref(1)

const isRange = ref()
const dateRange = ref(    {
      start: new Date(),
      end: null,
    })

const showDatePickerClickType = ref('')

const showFromCalendarInStore = computed(() => showDatePicker.value)
const target = ref(null)
const attributes = ref(null)

const cities = ref([]);
const stations = ref([]);



const defaultDepartureCity = computed(() => {
  const defaultStation = stations.value.find(city => city.id == 5)
  let result = null

  const locale = i18n.global.locale.value;

  if (!defaultStation) return

  if (locale === 'en')
    result = {
      id: defaultStation.id,
      name: defaultStation.name_en,
    }

  if (locale === 'ru')
    result = {
      id: defaultStation.id,
      name: defaultStation.name_ru,
    }

  if (locale === 'kg')
    result = {
      id: defaultStation.id,
      name: defaultStation.name_kg,
    }

  return result
})

const defaultArrivalCity = computed(() => {
  const defaultStation = stations.value.find(city => city.id == 2)
  let result = null

  const locale = i18n.global.locale.value;

  if (!defaultStation) return

  if (locale === 'en')
    result = {
      id: defaultStation.id,
      name: defaultStation.name_en,
    }

  if (locale === 'ru')
    result = {
      id: defaultStation.id,
      name: defaultStation.name_ru,
    }

  if (locale === 'kg')
    result = {
      id: defaultStation.id,
      name: defaultStation.name_kg,
    }

  return result
})



const vModel = computed({
  get() {
    if (isRange.value) {
      return dateRange.value
    }

    return dateRange.value.start
  },
  set() {
    // if (isRange.value) {
    //   ticket.dateRange = {
    //     start: ticket.singleDate,
    //     end: null,
    //   }
    // }
  },
})

const fetchCities = async function() {
  try {
    const response = await axios.get('https://ticket.railway.kg/api/cities/', { params: { page_size: 1000 } })
    cities.value.push(...response.data.results)
  } catch (error) {
    console.error('API data fetch error:', error)
  }
}

const fetchStations = async function() {
  try {
    const response = await axios.get('https://ticket.railway.kg/api/stations/', { params: { page_size: 1000 } })
    stations.value.push(...response.data.results)
  } catch (error) {
    console.error('API data fetch error:', error)
  }
}


onClickOutside(target, () => (showDatePicker.value = false))

function onDrag() {
  if (calendar.value.inputValue.start) {
    const parts = calendar.value.inputValue.start.split('.')
    const day = parseInt(parts[0])
    const month = parseInt(parts[1]) - 1
    const year = parseInt(parts[2])
    dateRange.value.start = new Date(year, month, day)
  }
}

function dateSelection(date) {
  dateSelectionCounter.value++

  if (dateSelectionCounter.value === 1) {
    secondDate.value = date
  }

  if (isRange.value && typeof date === 'object' && date) {
    dateRange.value = date
  } else {
    dateRange.value = { start: date, end: null }
  }
}

function showFromCalendar() {
  showDatePicker.value = !showDatePicker.value
  showDatePickerClickType.value = 'from'
}

function showToCalendar() {
  showDatePicker.value = !showDatePicker.value
  showDatePickerClickType.value = 'to'
  isRange.value = !isRange.value
  dateClickImitator(dateRange.value.start)
}

function dateClickImitator(target) {
  setTimeout(() => {
    dateRange.value.start = new Date()
    // Найти элемент на странице по классу
    const parentElement = document.querySelector('.id-' + moment(target).format('YYYY-MM-DD'))

    // Проверить, найден ли элемент
    if (parentElement) {
      // Найти дочерний элемент по классу
      const childElement = parentElement.querySelector('.vc-day-content')

      // Проверить, найден ли дочерний элемент
      if (childElement) {
        // Имитировать клик на дочернем элементе
        // @ts-ignore
        childElement.click()

        dateRange.value.start = target

        console.log('Клик на дочернем элементе с классом "childClassName" имитирован.')
      } else {
        console.log('Дочерний элемент с классом "childClassName" не найден.')
      }
    } else {
      console.log('Элемент с классом "parentClassName" не найден.')
    }
  }, 100)
}

watch(showFromCalendarInStore, () => {
  if (!showFromCalendarInStore.value) dateSelectionCounter.value = 0
})

watch(dateSelectionCounter, newVal => {
  setTimeout(function () {
    if (newVal === 1) {
      isRange.value = true
      dateClickImitator(secondDate.value)
    }

    if (showDatePickerClickType.value === 'from' && newVal === 3) {
      showDatePicker.value = false
    }

    if (showDatePickerClickType.value === 'to') {
      showDatePicker.value = false
    }
  }, 100)
})

function setNoReturnTicket() {
  isRange.value = false
  showDatePicker.value = false
  dateRange.value.end = null
}

function swap() {
  const toCityValue = toCity.value
  const fromCityValue = fromCity.value
  console.log(toCity.value)

  toCity.value = fromCityValue
  toCityName.value = fromCityValue.name_ru

  fromCityName.value = toCityValue?.name_ru
  fromCity.value = toCityValue
}


const handleSubmit = async () => {
  // isLoading.value = true
  showDatePicker.value = false
  const fromCityId = fromCity.value.id
  const toCityId = toCity.value.id
  const startDate = moment(dateRange.value.start).format('YYYY-MM-DD')
  const endDate = dateRange.value.end && moment(dateRange.value.end).format('YYYY-MM-DD')

  window.location.href=`https://ticket.railway.kg/result?from_city=${fromCityId}&to_city=${toCityId}&start_date=${startDate}&end_date=${endDate}&person=${person.value}`

}

onMounted(() => {
  fetchCities()
  fetchStations()

  const startDate = route.query.start_date
  const endDate = route.query.end_date

  if (startDate) dateRange.value.start = startDate
  if (endDate) dateRange.value.end = endDate
  if (startDate && endDate) isRange.value = true
})
</script>
