<template>
    <!-- features-area -->
    <section class="features-area">
        <div class="container">
            <div class="row justify-content-center" >
                <div  class="col-xl-4 col-lg-6 col-sm-10" v-for="item in firstElements" :key="item.id">
                  <router-link v-if="item.link==null" to="/#">
                    <div class="features-item">
                        <div class="features-icon">
                            <i class="ph ph-info"></i>
                        </div>
                        <div class="features-content">
                            <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                        </div>
                    </div>
                  </router-link>
                  <router-link v-else-if="!item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: item.link.page_slug } }">
                    <div class="features-item">
                      <div class="features-icon">
                        <i class="ph ph-info"></i>
                      </div>
                      <div class="features-content">
                        <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link v-else :to="item.link.url">
                    <div class="features-item">
                      <div class="features-icon">
                        <i class="ph ph-info"></i>
                      </div>
                      <div class="features-content">
                        <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                      </div>
                    </div>
                  </router-link>
                </div>
            </div>
            <div class="row justify-content-center" >
                <div class="col-xl-4 col-lg-6 col-sm-10" v-for="item in secondElements" :key="item.id">
                  <router-link v-if="item.link==null" to="/#">
                    <div class="features-item">
                      <div class="features-icon">
                        <i class="ph ph-info"></i>
                      </div>
                      <div class="features-content">
                        <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link v-else-if="!item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: item.link.page_slug } }">
                    <div class="features-item">
                      <div class="features-icon">
                        <i class="ph ph-info"></i>
                      </div>
                      <div class="features-content">
                        <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                      </div>
                    </div>
                  </router-link>
                  <router-link v-else :to="item.link.url">
                    <div class="features-item">
                      <div class="features-icon">
                        <i class="ph ph-info"></i>
                      </div>
                      <div class="features-content">
                        <h6 class="title">{{getLocaleText(item.title_kg, item.title_ru, item.title_en)}}</h6>
                      </div>
                    </div>
                  </router-link>
                </div>
            </div>
          <div class="row justify-content-center" >
            <div class="col-lg-6 col-sm-10">
              <router-link to="/anniversary">
                <div class="features-item features-item-primary">
                  <div class="features-icon">
                    <i class="ph ph-train"></i>
                  </div>
                  <div class="features-content">
                    <h6 class="title">{{ $t('We are 100 years old') }}</h6>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
    </section>
    <!-- features-area-end -->
</template>
<script>
import axios from "axios";
import getLocaleText from "../../locales/getLocaleText";

export default{
    name:'V_feature',
    data(){
      return {
        elements: [],
      }
    },
    created() {
      this.fetchElements();
    },
    methods: {
      getLocaleText,
      async fetchElements() {
        try {
          const response = await axios.get('body_links/');
          this.elements = response.data.filter(item => item.status);
        } catch (error) {
          console.error('API data fetch error:', error);
        }
      },
    },
    computed: {
      firstElements() {
        return this.elements.slice(0,3); // İlk üç öğeyi atlıyoruz
      },
      secondElements(){
        return this.elements.slice(3,6)
      }
    }
}
</script>
<style>

.features-item-primary{
  background-color: #145abe;
}
.features-item-primary .features-content .title{
  margin-bottom: 0;
  color: #ffffff;
}
.features-item-primary .features-icon i{
  color: #ffffff;
}

</style>