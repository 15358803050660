<template>
  <div class="col-lg-12">
    <div class="inner-blog-item">
      <div class="inner-blog-thumb" v-if="this.video">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <video class="w-100" controls v-if="newsData.video !='' && newsData.video !=null">
              <source :src="newsData.video">
            </video>
            <iframe class="w-100" style="height: 450px"
                    :src="'https://www.youtube.com/embed/' + urlId" v-if="newsData.youtube_url !='' && newsData.youtube_url !=null">
            </iframe>
          </div>
        </div>
      </div>
      <div class="inner-blog-content">
        <h2 class="title">{{ getLocaleText(newsData.title_kg,newsData.title_ru,newsData.title_en)}}</h2>
        <div class="important_style" v-html="getLocaleText(newsData.content_kg,newsData.content_ru,newsData.content_en)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";

export default {
  data() {
    return {
      readyToMount: false,
      newsData: {},
      urlId:'',
      video:false,
    };
  },
  props: ['slug'],
  setup() {
    return {getLocaleText};
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        if (!this.slug) {
          console.error('Link prop is not set correctly.');
          return;
        }
        const response = await axios.get(`video_galery/${this.slug}`);
        this.newsData = response.data;
        if(this.newsData.youtube_url!='' && this.newsData.youtube_url!=null){
          var splited = this.newsData.youtube_url.split("v=");
          var splitedAgain = splited[1].split("&");
          this.urlId = splitedAgain[0];
        }
        this.readyToMount = true;

      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    initSlider() {
      this.$nextTick(() => {
        this.video = true;
      });
    }
  },
  watch: {
    readyToMount(newValue) {
      if (newValue) {
        this.initSlider();
      }
    },
  }
}
</script>
<style>
</style>