import { ref, onMounted, onUnmounted } from 'vue';

export function useBVI() {
    const imageMode = ref('on'); // 'on', 'off', 'grayscale'

    const checkActiveImageMode = () => {
        const bviPanel = document.querySelector('.bvi-panel');
        if (bviPanel) {
            const offButton = document.querySelector('.bvi-link.bvi-images-off.active');
            const grayscaleButton = document.querySelector('.bvi-link.bvi-images-grayscale.active');

            if (offButton) {
                imageMode.value = 'off';
            } else if (grayscaleButton) {
                imageMode.value = 'grayscale';
            } else {
                imageMode.value = 'on';
            }
        } else {
            imageMode.value = 'on';
        }
    };

    const handleImageModeChange = (e) => {
        if (e.target.classList.contains('bvi-images-off')) {
            imageMode.value = 'off';
        } else if (e.target.classList.contains('bvi-images-grayscale')) {
            imageMode.value = 'grayscale';
        } else if (e.target.classList.contains('bvi-images-on')) {
            imageMode.value = 'on';
        }
    };

    let observer = null;

    const observeBviPanel = () => {
        observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    checkActiveImageMode();
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });
    };

    onMounted(() => {
        checkActiveImageMode();
        observeBviPanel();

        const bviPanel = document.querySelector('.bvi-panel');
        if (bviPanel) {
            bviPanel.addEventListener('click', handleImageModeChange);
        }
    });

    onUnmounted(() => {
        const bviPanel = document.querySelector('.bvi-panel');
        if (bviPanel) {
            bviPanel.removeEventListener('click', handleImageModeChange);
        }
        if (observer) {
            observer.disconnect();
        }
    });

    return {
        imageMode
    };
}