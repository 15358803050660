<template>
  <div
    ref="dropdown"
    class="flex bg-white items-center w-full relative h-[53px]">
    <!-- eslint-disable -->
    <i class="ph ph-map-pin absolute left-4 text-[25px]"></i>
    <input
      ref="inputRef"
      v-model="itemValue"
      type="text"
      :placeholder="placeholder"
      class="p-[15px] border-none outline-none bg-gray-100 pl-[50px] rounded-xl border-l border-red-500 w-full"
      autocapitalize="none"
      autocomplete="off"
      autocorrect="off"
      @input="inputHandler"
      @click="toggleList" />
    <div
      v-if="isShow"
      class="absolute bg-white top-[100%] w-full rounded-bl-md rounded-br-md shadow-md z-[100] list">
      <div class="flex flex-col max-h-[200px] overflow-y-auto py-3">
        <span
          v-for="item in filteredCities"
          :key="item.id"
          class="cursor-pointer hover:opacity-80 px-[15px] py-[10px] hover:bg-gray-200"
          @click="selectCity(item)">
          {{ item.name_ru }}
        </span>
        <div
          v-if="!filteredCities.length"
          class="text-center text-sm text-gray-600">
          Ничего не найдено
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { ref, computed, watch, onMounted } from 'vue'
import { watchOnce } from '@vueuse/core'
import { defineProps } from 'vue'
// import { useRoute } from 'vue-router'
import { defineEmits } from 'vue';

const props = defineProps(['fromCity', 'toCity', 'toCityName', 'fromCityName', 'cities', 'placeholder', 'directionType', 'defaultCityId', 'defaultCityName'])

const emits = defineEmits(['setFromCityName', 'setToCityName', 'setToCity', 'setFromCity'])
// const route = useRoute()


const dropdown = ref(null)
const inputRef = ref(null)

const isShow = ref(false)
const inputValue = ref()
const inputValueReserve = ref()

onClickOutside(dropdown, () => {
  isShow.value = false
})

watch(isShow, () => {
  if (isShow.value) return

  const previousCity = props.cities.find(city => city.name_ru === inputValueReserve.value)

  const isValueChanged = inputValue.value !== inputValueReserve.value
  const isInputDefaultCity = inputValueReserve.value === props.defaultCityName
  console.log('show watch', inputValue.value, inputValueReserve.value, isValueChanged, isInputDefaultCity)
  setValues(
    isValueChanged && !isInputDefaultCity
      ? { id: previousCity.id, name: previousCity.name_ru }
      : previousCity
        ? { id: previousCity.id, name: previousCity.name_ru }
        : { id: props.defaultCityId, name: props.defaultCityName },
  )
  setCursorOnStart()
})

function toggleList() {
  isShow.value = !isShow.value

  if (isShow.value) {
    setCursorOnStart()
  }
}

const itemValue = computed({
  get() {
    return props.directionType === 'from' ? props.fromCityName : props.toCityName
  },
  set(newValue) {

    if (props.directionType === 'from') {
      emits('setFromCityName', newValue)
    } else {
      emits('setToCityName', newValue)
    }
  },
})

function setCursorOnStart() {
  inputRef.value.selectionStart = 0
  inputRef.value.selectionEnd = 0
}

const filteredCities = computed(() => {
  if ([props.fromCity?.name_ru, props.toCity?.name_ru].includes(itemValue.value)) {
    return props.cities
  }
  return props.cities.filter(item => item.name_ru.toLowerCase().includes(itemValue.value.toLowerCase()))
})

function inputHandler() {
  const cursorPosition = inputRef.value.selectionStart
  const textBeforeCursor = itemValue.value.substring(0, cursorPosition)

  itemValue.value = textBeforeCursor
  inputRef.value.setSelectionRange(cursorPosition, cursorPosition)
}

function selectCity(v) {

  if (props.directionType === 'from') {
    emits('setFromCity', v)
  }

  if (props.directionType === 'to') {
    emits('setToCity', v)
  }
  isShow.value = false
  inputValue.value = v.name_ru
  inputValueReserve.value = v.name_ru
}

function setValues({ id, name }) {
  inputValue.value = name
  inputValueReserve.value = name

  if (props.directionType === 'from' && props.fromCityName !== name) {
    console.log('setValues', id, name, props.directionType)
    emits('setFromCityName', name)
    emits('setFromCity', {
      id,
      name_ru: name,
    })
  }

  if (props.directionType === 'to' && props.toCityName !== name) {
    emits('setToCityName', name)
    emits('setToCity', {
      id,
      name_ru: name,
    })
  }
}

// watch(route, () => {
//   updateValuesByQueries()
// })

watchOnce(props, () => {
  setValues({ id: props.defaultCityId, name: props.defaultCityName })
})

onMounted(async () => {
  // updateValuesByQueries()
})

// async function updateValuesByQueries() {
//   if (route.query.from_city && route.query.to_city) {
//     await Promise.all([
//       props.getCityById('from', route.query.from_city),
//       props.getCityById('to', route.query.to_city),
//     ])

//     if (props.directionType === 'from') {
//       setValues({
//         id: props.fromCity?.id,
//         name: props.fromCity?.name_ru,
//       })

//       inputValue.value = props.fromCity?.name_ru
//       inputValueReserve.value = props.fromCity?.name_ru
//     }

//     if (props.directionType === 'to') {
//       setValues({
//         id: props.toCity?.id,
//         name: props.toCity?.name_ru,
//       })

//       inputValue.value = props.toCity?.name_ru
//       inputValueReserve.value = props.toCity?.name_ru
//     }
//   }
// }
</script>

<style lang="scss">
.dropdown {
  position: absolute;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  top: 100%;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 11;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 0 1px 0 rgba(0, 0, 0, 0.2);
}

.dropdown__select {
  display: flex;
  flex-directionType: column;
  gap: 10px;
  max-height: 200px;
  overflow-y: scroll;
}

.list {
  border-top: 1px solid rgb(233, 233, 233);
}

// input {
//   background: transparent;
//   border: none;
//   padding: 20px;
//   outline: none;
// }
</style>
<!-- display: flex;
background: #fff;
align-items: center;
position: relative;
width: 100%; -->
