<template>
  <div
    class="p-[15px] !border-none outline-none bg-gray-100 rounded-xl cursor-pointer relative z-10 flex items-center justify-between">
    <i class="ph ph-users text-[25px] mr-2"></i>
    <i
      class="ph ph-plus text-[12px]"
      @click="increaseHandler"></i>
    <label>
      <input
        v-model="value"
        type="number"
        class="text-center w-[20px]" />
    </label>
    <i
      class="ph ph-minus text-[12px]"
      @click="discreaseHandler"></i>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from 'vue'

const value = ref(1)

const props = defineProps(['person'])

const emits = defineEmits(['setPerson'])

watch(value, (newValue) => {
  if (newValue <= 0) {
    value.value = 1
  }

  emits('setPerson', newValue)
})

function increaseHandler() {
  value.value++
}

function discreaseHandler() {
  if (props.person > 1) {
    value.value--
  }
}
</script>
