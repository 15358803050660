<template>
    <!-- service-area -->
    <section class="service-area">
        <div class="container">
            <div class="row align-items-end mb-50">
                <div class="col-md-8">
                    <div class="section-title">
                        <h2 class="title">{{ $t('Services') }}</h2>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="service-nav"></div>
                </div>
            </div>
            <div class="row service-active">
                <div class="service-item" v-for="item in elements" :key="item.id">
                  <router-link v-if="item.link==null" to="/#">
                    <div class="service-icon">
                      <i class="fa fa-train"></i>
                    </div>
                    <div class="service-content">
                      <h2 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en,) }}</h2>
                    </div>
                  </router-link>
                  <router-link v-else-if="!item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: item.link.page_slug } }">
                    <div class="service-icon">
                        <i class="ph ph-train"></i>
                    </div>
                    <div class="service-content">
                        <h2 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en,) }}</h2>
                    </div>
                  </router-link>
                  <router-link v-else :to="item.link.url">
                    <div class="service-icon">
                      <i class="ph ph-train"></i>
                    </div>
                    <div class="service-content">
                      <h2 class="title">{{ getLocaleText(item.title_kg, item.title_ru, item.title_en,) }}</h2>
                    </div>
                  </router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- service-area-end -->
</template>
<script>
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";

export default{
    name:'V_links',
    data(){
        return{
          elements: [],
          readyToMount: false,
        }
    },
    setup() {
      return {getLocaleText};
    },
    mounted() {
      this.fetchElements();
    },
    methods: {
      fetchElements() {
        try {
          axios.get('service_links/').then(res=>{
            this.elements = res.data.filter(item => item.status);
            this.readyToMount = true;
          })
        } catch (error) {
          console.error('API data fetch error:', error);
        }
      },
        slider() {
          this.$nextTick(() => {
            let slider = window.$('.service-active').not('.slick-initialized');
            slider.slick({
              dots: false,
              infinite: true,
              speed: 2000,
              autoplay: true,
              arrows: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              prevArrow: '<span class="slick-prev"><i class="fa-solid fa-arrow-left"></i></span>',
              nextArrow: '<span class="slick-next"><i class="fa-solid fa-arrow-right"></i></span>',
              appendArrows: ".service-nav",
              responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                  }
                },
                {
                  breakpoint: 575,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                  }
                },
              ]
            })
          })
        }
    },
  watch: {
    readyToMount(newValue) {
      if (newValue) {
        this.slider();
      }
    }
  }
}
</script>