<template>
  <div class="col-lg-8">
    <div class="page-actions print-hide" >
      <div v-if="allData.components==null || allData.components ==''" class="page-action-item action-print">
        <a @click="printPage" >
          <h6 style="color: #0d6efd">{{ $t('Print') }}</h6>
          <i style="padding-left: 10px;color: #0d6efd" class="fa-solid fa-print fa-xl"></i>
        </a>
      </div>
      <div class="page-action-item action-share">
        <div class="d-flex align-items-center">
          <a class="text-primary facebook" @click="shareOnFacebook">
            <i class="ph ph-facebook-logo"></i>
          </a>
          <a class="text-primary twitter" @click="shareOnTwitter">
            <i class="ph ph-twitter-logo"></i>
          </a>
          <a class="text-primary whatsapp" @click="shareOnWhatsApp">
            <i class="ph ph-whatsapp-logo"></i>
          </a>
          <a class="text-primary telegram" @click="shareOnTelegram">
            <i class="ph ph-telegram-logo"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="inner-blog-item">
        <div class="inner-blog-content">
            <h2 style="display: inline-block" class="title">{{ getLocaleText(allData.title_kg,allData.title_ru,allData.title_en)}}</h2>
            <div class="important_style" v-html="getLocaleText(allData.content_kg,allData.content_ru,allData.content_en)"></div>

          <div class="faq-area p-0" v-if="allData.components">
            <div class="faq-content">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item" v-for="component in allData.components" :key="component.id">
                  <h2 class="accordion-header" :id="'heading'+component.id">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+component.id" aria-expanded="false" :aria-controls="'collapse'+component.id">
                      {{ getLocaleText(component.title_kg,component.title_ru,component.title_en) }}
                    </button>
                  </h2>
                  <div :id="'collapse'+component.id" class="accordion-collapse collapse" :aria-labelledby="'heading'+component.id" data-bs-parent="#accordionExample" style="">
                    <div class="accordion-body">
                      <div class="important_style" v-html="getLocaleText(component.content_kg,component.content_ru,component.content_en)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import getLocaleText from '@/locales/getLocaleText';
export default {
  name: 'ContentView',
  data() {
    return {
      currentUrl:window.location.href,
        allData: {}
    };
  },
  setup() {
    return {getLocaleText};
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    '$route.query.slug_name': {
      immediate: true,
      handler() {
        this.fetchData();
      }
    }
  },
  methods: {
    printPage(){
      window.print();
    },
    shareOnTwitter (){
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnFacebook(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}`, '_blank', 'width=400,height=300');
    },
    shareOnWhatsApp(){
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    shareOnTelegram(){
      window.open(`tg://msg_url?url=${encodeURIComponent(this.currentUrl)}`, '_blank');
    },
    async fetchData() {
      try {
        if (!this.$route.query.slug_name) {
          console.error('Link prop is not set correctly.');
          return;
        }
        const response = await axios.get(`pages/${this.$route.query.slug_name}/`);
        this.allData = response.data;
        console.log(this.allData)
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
  },
}
</script><style>

</style>