<template>
	<!-- header-area -->
	<header class="print-hide">
    <!-- Modal toggle -->
		<div id="sticky-header2" class="header-top ">
			<div class="container custom-container">
				<div class="row align-items-center">
					<div class="col-xl-4">
						<div class="header-top-left">
							<a href="#" class="d-flex align-items-center">
								<i class="ph ph-train-simple"></i><a target="_blank" href="https://ticket.railway.kg/">{{ $t('You can now buy tickets here') }}</a>
							</a>
						</div>
					</div>
					<div class="col-xl-8">
						<div class="header-top-right">
							<ul>
                <li><a href="https://kjd.kg/" target="_blank">{{ $t('Old portal') }}</a></li>
								<li><a class="d-flex align-items-center bvi-open" href="#"><i class="ph ph-eye" title="Версия сайта для слабовидящих"></i></a></li>
								<li ><a href="#" @click="toggleModal"> <i class="ph ph-chat-circle-dots"></i>{{ $t('Feedback') }}</a></li>
								<li><a class="d-flex align-items-center" @click="toggleModal2" href="#"><i class="ph ph-magnifying-glass"></i>{{ $t('Search') }}</a></li>
								<li>
									<div class="d-flex align-items-center">
										<router-link class="d-flex align-items-center" :to="{ name: currentRouteName, query: { lang: 'kg' } }" :key="'kg'" :class="{ active: selectedLocale === 'kg' }" @click="changeLocale('kg')">
											Кыргызча
										</router-link>
										<router-link class="d-flex align-items-center ms-3" :to="{ name: currentRouteName, query: { lang: 'ru' } }" :key="'ru'" :class="{ active: selectedLocale === 'ru' }" @click="changeLocale('ru')">
											Русский
										</router-link>
										<router-link class="d-flex align-items-center ms-3" :to="{ name: currentRouteName, query: { lang: 'en' } }" :key="'en'" :class="{ active: selectedLocale === 'en' }" @click="changeLocale('en')">
											English
										</router-link>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
    <Modal @close="toggleModal2" :modalActive="modalActive2">
      <section class="contact-area" style="padding: 40px 0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="contact-form" @submit.prevent="submitSearch">
                <form action="#">
                    <div class="col-md-12">
                      <div class="form-grp">
                        <input type="text" v-model="searchKeyword" :placeholder="$t('what are you looking for?')">
                      </div>
                    </div>
                  <button type="submit" class="btn">{{ $t('Search') }}</button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
    <Modal @close="toggleModal" :modalActive="modalActive">
      <section class="contact-area" style="padding: 40px 0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="section-title text-center mb-40">
                <h2 class="title">{{ $t('Leave a request') }}</h2>
              </div>
              <div class="contact-form" @submit.prevent="submitForm">
                <form action="#">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-grp">
                        <input type="text" v-model="formData.fullName" :placeholder="$t('Full name')" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-grp">
                        <input type="email" v-model="formData.email" :placeholder="$t('Email')" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-grp">
                        <input type="text" v-model="formData.subject" :placeholder="$t('Subject')" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-grp">
                        <input type="number" v-model="formData.phoneNumber" :placeholder="$t('Phone number')" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-grp">
                      <input type="file" @change="handleFileUpload" ref="fileInput" :placeholder="$t('Select a file')" required>
                    </div>
                  </div>
                  <div class="form-grp">
                    <textarea name="message" v-model="formData.message" :placeholder="$t('Text of the appeal')" required></textarea>
                  </div>
                  <div class="submit-btn text-center">
                    <button type="submit" class="btn">{{ $t('Submit') }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
		<div id="sticky-header" class="menu-area transparent-header">
			<div class="container custom-container">
				<div class="row">
					<div class="col-12">
						<div class="mobile-nav-toggler"><i class="fas fa-bars"></i></div>
						<div class="menu-wrap">
							<nav class="menu-nav">
								<div class="logo"><router-link to="/"><img class="bvi-img" src="@/assets/img/logo/railway_white_beta.png" alt=""></router-link></div>
								<div class="navbar-wrap main-menu d-none d-lg-flex" ref="mainMenu">
									<ul class="navigation" v-if="readyToMount">
										<li class="menu-item-has-children" v-for="item in elements" :key="item.id">
											<router-link to="#">
												{{ getLocaleText(item.title_kg, item.title_ru, item.title_en) }}
											</router-link>
											<ul class="submenu" v-if="item.links">
												<li v-for="sub_item in item.links" :key="sub_item.id">
													<router-link v-if="sub_item.link == null" to="/#">
														{{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
													</router-link>
													<router-link v-else-if="!sub_item.link.url.startsWith('/')" :to="{ path: '/content', query: { slug_name: sub_item.link.page_slug } }">
														{{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
													</router-link>
													<router-link v-else :to="sub_item.link.url">
														{{ getLocaleText(sub_item.title_kg, sub_item.title_ru, sub_item.title_en) }}
													</router-link>
												</li>
											</ul>
										</li>
									</ul>
								</div>
								<div class="header-action d-none d-md-block">
									<ul>
										<li class="header-btn sign-in"><a class="btn" href="https://asu.railway.kg">{{ $t('Personal Account') }}</a></li>
									</ul>
								</div>
							</nav>
						</div>
						<!-- Mobile Menu  -->
						<div class="mobile-menu">
							<nav class="menu-box">
								<div class="close-btn"><i class="fa-solid fa-xmark"></i></div>
								<div class="nav-logo"><a href="#"><img class="bvi-img" src="@/assets/img/logo/logo_kjd_1.png" alt="" title=""></a>
								</div>
								<div class="menu-outer">
								</div>
								<div class="social-links">
									<ul class="clearfix">
										<li><a href="#"><i class="ph ph-twitter-logo"></i></a></li>
										<li><a href="#"><span class="ph ph-facebook-logo"></span></a></li>
										<li><a href="#"><span class="ph ph-instagram-logo"></span></a></li>
										<li><a href="#"><span class="ph ph-youtube-logo"></span></a></li>
									</ul>
								</div>
							</nav>
						</div>
						<div class="menu-backdrop"></div>
						<!-- End Mobile Menu -->
					</div>
				</div>
			</div>
		</div>
	</header>
	<!-- header-area-end -->
</template>


<script>
// /* eslint-disable */
import axios from 'axios';
import getLocaleText from '@/locales/getLocaleText';
import {onMounted } from "vue";
import Modal from "./Modal.vue";
import { required, email, minLength, numeric } from 'vuelidate/lib/validators';

export default {
	name: 'V_header',
	data() {
		return {
			selectedLocale: this.$i18n.locale,
			elements: [],
      modalActive: false,
      modalActive2: false,
      readyToMount: false,
      searchKeyword: '',
      formData: {
        fullName: '',
        phoneNumber: '',
        email: '',
        subject: '',
        message: '',
        file:null,
        status:true,
      },
      validations: {
        formData: {
          file: {
            required,

          },
          fullName:{
            required,
          },
          phoneNumber:{
            required,
            numeric
          },
          subject:{
            required,
          },
          message:{
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(6)
          }
        }
      },
		}
	},
  components: {
    Modal,
  },
	setup() {
    onMounted(() => {
      // eslint-disable-next-line no-undef
      // new isvek.Bvi({
      //   speech: false,
      //   target: ".bvi-open"
      // })
    })
		return { getLocaleText,};
	},
	created() {
		this.fetchElements();
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		}
	},

	methods: {
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    toggleModal2() {
      this.modalActive2 = !this.modalActive2;
    },
    submitSearch() {
      // input alanından alınan anahtar kelime ile birlikte /search adresine yönlendirme yapılacak
      this.$router.push({ path: '/search', query: { keyword: this.searchKeyword } });
      // Modal'ı kapatma
      this.searchKeyword = '';
      this.toggleModal2();
    },
    handleFileUpload() {
      const files = this.$refs.fileInput.files;
      // Sadece bir dosya seçildiğinden emin olun
      if (files.length === 1) {
        // FormData nesnesine dosyayı ekleyin
        this.formData.file = files[0];
      }
    },
    async searchForm(){

    },
    async submitForm() {
      try {
        const formData = new FormData();
        formData.append('full_name', this.formData.fullName);
        formData.append('email', this.formData.email);
        formData.append('subject', this.formData.subject);
        formData.append('phone_number', this.formData.phoneNumber);
        formData.append('text', this.formData.message);
        if (this.formData.file) {
          formData.append('file', this.formData.file);
        }
        await axios.post('/feedback/', formData);
        this.$toast.success( 'successfully') ;
        this.formData = {
          fullName: '',
          phoneNumber: '',
          email: '',
          subject: '',
          message: '',
          file: null  // Dosya alanını sıfırla
        };
        this.toggleModal();
      } catch (error) {
        console.error('Error sending feedback:', error);
      }
    },
		async fetchElements() {
			try {
				const response = await axios.get('main_menu/');
				this.elements = response.data;
				this.elements = this.elements.sort((a, b) => a.order - b.order);
        this.readyToMount = true;
			} catch (error) {
				console.error('API data fetch error:', error);
			}
		},
		changeLocale(locale) {
			this.selectedLocale = locale;
			this.$i18n.locale = locale;
			localStorage.setItem("lang", locale);
			this.$router.replace({ query: { ...this.$route.query, lang: locale } }).catch(err => {
				if (err.name !== 'NavigationDuplicated') {
					throw err;
				}
			});
		},
    mobileNav(){
      this.$nextTick(() => {
        //SubMenu Dropdown Toggle
        if (window.$('.menu-area li.menu-item-has-children ul').length) {
        window.$('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
        }

        //Mobile Nav Hide Show
        if (window.$('.mobile-menu').length) {
          let mobileMenuContent = window.$('.menu-area .main-menu').html();
          window.$('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

          //Dropdown Button
          window.$('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
            window.$(this).toggleClass('open');
            window.$(this).prev('ul').slideToggle(500);
          });

          //Menu Toggle Btn
          window.$('.mobile-nav-toggler').on('click', function () {
            window.$('body').addClass('mobile-menu-visible');
          });

          //Menu Toggle Btn
          window.$('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
            window.$('body').removeClass('mobile-menu-visible');
          });
        }
      })
    }
	},
	mounted() {
    // this.mobileNav()
	},
  watch: {
    readyToMount(newValue) {
      if (newValue) {
        this.mobileNav()
      }
    }
  }
}
</script>

<style>
.search-form {
  text-align: center;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

  .search-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

  h1,
  p {
    margin-bottom: 16px;
  }

  h1 {
    font-size: 32px;
  }

  p {
    font-size: 18px;
  }
</style>
