<template>
  <div class="brand-area brand-bg">
    <div class="container">
      <div class="brand-active">
        <div class="h-100 px-2" v-for="item in elements" :key="item.id">
          <div class="features-item">
            <div class="features-icon">
              <i class="ph ph-link"></i>
            </div>
            <div class="features-content">
              <h5 class="title fs-6">{{item.title_ru}}</h5>
            </div>
            <a :href="item.url" target="_blank" class="stretched-link"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getLocaleText from "@/locales/getLocaleText";
import axios from "axios";

export default {
  name:"V_siteslink",
  data(){
    return{
      elements: [],
      readyToMount: false,
    }
  },
  setup() {
    return {getLocaleText};
  },
  mounted() {
    this.fetchElements();
  },
  methods: {
    fetchElements() {
      try {
        axios.get('sos_organs/').then(res=>{
          this.elements = res.data.filter(item => item.status);
          this.readyToMount = true;
        })
      } catch (error) {
        console.error('API data fetch error:', error);
      }
    },
    slider() {
      this.$nextTick(() => {
        window.$('.brand-active').slick({
          dots: false,
          infinite: true,
          speed: 1000,
          // autoplay: true,
          arrows: true, // Arrows are enabled here
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button class="slick-next" aria-label="Next" type="button"><i class="fas fa-chevron-right"></i></button>',
          slidesToShow: 4,
          slidesToScroll: 2,
          padding: 20,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              }
            },
          ]
        });
      })
    }
  },
  watch: {
    readyToMount(newValue) {
      if (newValue) {
        this.slider();
      }
    }
  }
}
</script>

<style>
.brand-area .slick-track{
  display: flex !important;
  padding: 20px 0;
}
.brand-area .slick-slide{
  height: inherit !important;
}
.brand-area .slick-slide > div{
  height: 100%;
}
.brand-area .features-item{
  height: 100%;
  padding: 20px;
  margin-bottom: 0;
}
.brand-area .features-icon{
  margin-right: 10px;
}
.brand-area .features-item:hover,
.brand-area .features-item:focus{
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  font-size: 20px; /* Icon font size */
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:hover, .slick-next:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
</style>